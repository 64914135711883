.Title {
  text-align: left;
  color: var(--color-basic-dark-grey);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  margin-top: 5px;
  padding-left: 10px;
  min-height: 16px;
}

.LabelAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;

  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.ValueAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  margin-left: 8px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.Circle {
  box-shadow: 0px 0px 6px rgba(0, 182, 201, 0.3),
    inset 0px 0px 10px rgba(0, 182, 201, 0.25);
}

.Unit {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  margin-right: 3px;
}

.Subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */
  text-align: center;
  margin-top: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Basic/Black */

  color: #1c1c1c;
}
