.Modal {
  max-width: 100% !important;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  /* border: 1px solid var(--color-basic-lightblue-1); */
  background: var(--color-basic-lightblue-1);
  /* margin-top: 12px; */
  /* margin-left: 20px; */
  min-height: 90%;
  max-height: 100%;
  /* margin-bottom: 40px; */
  margin-right: 20px;
  font-family: var(--ideal-sans);
}

.ModalHeader {
  border: none;
  border-radius: 23px 23px 0px 0px;
  background: var(--color-basic-lightblue-1);
  display: flex !important;
}

.ModalHeader > :global.modal-title {
  display: flex;
  align-items: flex-start;
}

.Modal > :global.modal-content {
  background: var(--color-basic-lightblue-1);
  border: none !important;
  border-radius: 23px;
}

.Title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 100px;
  margin-bottom: 10px;
}

.ProjectCode {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: rgba(13, 142, 202, 0.6);
  padding-left: 100px;
}

.SectorLabel {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  margin-left: 8px;
}

.SectorValue {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.Back {
  cursor: pointer;
  padding-left: 100px;
  margin-bottom: 24px;
}

.BackLabel {
  margin-left: 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  color: #585858;
}

.GoHover {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  font-feature-settings: 'pnum' on, 'lnum' on;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Basic/Black */

  color: #1c1c1c;
  text-align: center;
  margin-top: 8px;
}
