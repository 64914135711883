.Label {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-dark-grey);
}

.Select {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-basic-black);
}
