:root {
  --color-solid: #8f9091;
  --color-primary-solid: #0d8eca;
  --color-primary-light: #72d2ff;
  --black-chart: #333333;
  --grey-3: #999999;
  --color-primary-solid-60: #0d8eca;
  --color-primary-linear: linear-gradient(to bottom, #0d8eca 0%, #72d2ff 100%);
  --color-basic-black: #1c1c1c;
  --color-basic-dark-grey: #585858;
  --color-basic-light-grey: #a0a0a0;
  --color-basic-white-100: #f9fafe;
  --color-basic-white-90: #f9fafe;
  --color-basic-white-70: #f9fafe;
  --color-ppr-green-solid: #1bae79;
  --color-ppr-green-light: #21d993;
  --color-ppr-red-solid: #f5001d;
  --color-ppr-red-light: #ff8795;
  --color-ppr-yellow-solid: #febf44;
  --color-ppr-yellow-light: #fcda99;
  --color-basic-lightblue-1: #eef1fa;
  --color-basic-lightblue-2: #e2edf6;
  --color-sectors-solid-anr: #a8bb00;
  --color-sectors-solid-others: #4e4e4e;
  --color-sectors-solid-psm: #055a82;
  --color-sectors-solid-hlt: #8dc63f;
  --color-sectors-solid-ind: #fdb515;
  --color-sectors-solid-wus: #f57f29;
  --color-sectors-solid-ene: #68c5ea;
  --color-sectors-solid-edu: #e9532b;
  --color-sectors-solid-ict: #a073ff;
  --color-sectors-solid-fin: #c63edc;
  --color-sectors-solid-tra: #00b6c9;
  --color-sectors-light-anr: #d5e073;
  --color-sectors-light-others: #a1a1a1;
  --color-sectors-light-psm: #7698a8;
  --color-sectors-light-hlt: #bad299;
  --color-sectors-light-ind: #ffd986;
  --color-sectors-light-wus: #f8ad77;
  --color-sectors-light-ene: #abddf1;
  --color-sectors-light-edu: #fbaa94;
  --color-sectors-light-ict: #a68ae0;
  --color-sectors-light-fin: #d9aae0;
  --color-sectors-light-tra: #87d0d8;
  --color-sectors-linear-anr: linear-gradient(
    to bottom,
    #a8bb00 0%,
    #d5e073 100%
  );
  --color-sectors-linear-other: linear-gradient(
    to bottom,
    #4e4e4e 0%,
    #a1a1a1 100%
  );
  --color-sectors-linear-psm: linear-gradient(
    to bottom,
    #055a82 0%,
    #7698a8 100%
  );
  --color-sectors-linear-hlt: linear-gradient(
    to bottom,
    #6ab404 0%,
    #bad299 100%
  );
  --color-sectors-linear-ind: linear-gradient(
    to bottom,
    #fdb515 0%,
    #ffd986 100%
  );
  --color-sectors-linear-wus: linear-gradient(
    to bottom,
    #f57f29 0%,
    #f8ad77 100%
  );
  --color-sectors-linear-ene: linear-gradient(
    to bottom,
    #68c5ea 0%,
    #abddf1 100%
  );
  --color-sectors-linear-edu: linear-gradient(
    to bottom,
    #e9532b 0%,
    #fbaa94 100%
  );
  --color-sectors-linear-ict: linear-gradient(
    to bottom,
    #713edc 0%,
    #a68ae0 100%
  );
  --color-sectors-linear-xxx: linear-gradient(
    to bottom,
    #c63edc 0%,
    #d9aae0 100%
  );
  --color-sectors-linear-tra: linear-gradient(
    to bottom,
    #0098a8 0%,
    #87d0d8 100%
  );
  --color-light-blue-grey: #a4a9c1;
  --color-modality-linear-grant: linear-gradient(
    to bottom,
    #0088c7 0%,
    #79d1fa 100%
  );
  --color-modality-linear-loan: linear-gradient(
    to bottom,
    #00b6c9 0%,
    #8eeef8 100%
  );
  --color-modality-linear-ta: linear-gradient(
    to bottom,
    #63ccec 0%,
    #bceefd 100%
  );
  --color-modality-linear-guarantee: linear-gradient(
    to bottom,
    #019fca 0%,
    #7bd7f0 100%
  );
  --color-modality-linear-equity: linear-gradient(
    to bottom,
    #75b5ca 0%,
    #bfe1ec 100%
  );
  --color-modality-solid-grant: #0088c7;
  --color-modality-solid-loan: #00b6c9;
  --color-modality-solid-ta: #63ccec;
  --color-modality-solid-guarantee: #019fca;
  --color-modality-solid-equity: #75b5ca;
  --color-modality-light-grant: #79d1fa;
  --color-modality-light-loan: #8eeef8;
  --color-modality-light-ta: #bceefd;
  --color-modality-light-guarantee: #7bd7f0;
  --color-modality-light-equity: #75b5ca;
  --color-modality-light-fund: #98c9df;
  --color-modality-solid-fund: #7595a4;
  --color-modality-light-direct-investment: #4d8caa;
  --color-modality-solid-direct-investment: #135a7b;
  --color-fund-solid-tasf: #0088c7;
  --color-fund-light-tasf: #79d1fa;
  --color-fund-solid-adf: #00b6c9;
  --color-fund-light-adf: #8eeef8;
  --color-fund-solid-col: #63ccec;
  --color-fund-light-col: #bceefd;
  --color-fund-solid-ocr: #019fca;
  --color-fund-light-ocr: #7bd7f0;
  --color-fund-solid-others: #75b5ca;
  --color-fund-light-others: #75b5ca;

  --color-ppr-linear-yellow: linear-gradient(
    to bottom,
    #febf44 0%,
    #fed17a 100%
  );
  --color-ppr-linear-red: linear-gradient(to bottom, #f5001d 0%, #ff8795 100%);
  --color-ppr-linear-green: linear-gradient(
    to bottom,
    #1bae79 0%,
    #21d993 100%
  );
  --color-ppr-solid-green: #1dbc81;
  --color-ppr-solid-red: #f71831;
  --color-ppr-solid-yellow: #fdb515;
  --color-nonsov-linear-grey-1: linear-gradient(
    to bottom,
    #585858 0%,
    #a0a0a0 100%
  );
  --color-linear-grey-1: linear-gradient(to bottom, #585858 0%, #928f8f 100%);
  --color-linear-grey-2: linear-gradient(to bottom, #858687 0%, #bdbdbd 100%);
  --color-linear-grey-3: linear-gradient(to bottom, #b3b3b5 0%, #dadada 100%);
  --color-linear-grey-4: linear-gradient(to bottom, #d1d2d4 0%, #eaebee 100%);
  --color-linear-grey-5: linear-gradient(to bottom, #e0e1e4 0%, #f2f3f4 100%);
  --color-linear-grey-6: linear-gradient(to bottom, #f1f2f6 0%, #eff0f1 100%);
  --color-solid-grey-1: #626262;
  --color-solid-grey-2: #8f9091;
  --color-solid-grey-3: #cacacb;
  --color-solid-grey-4: #d9dadc;
  --color-solid-grey-5: #e1e1e1;
  --color-solid-grey-6: #f1f1f1;
  --ideal-sans: 'Ideal Sans';
}

.text-style-button-l-medium {
  font-size: 1rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

.text-style-button-m-medium {
  font-size: 0.875rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-button-m-light {
  font-size: 0.875rem;
  font-family: 'Ideal Sans';
  font-weight: 300;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-cta-m-light {
  font-size: 1rem;
  font-family: 'Ideal Sans';
  font-weight: 300;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-cta-xs-light {
  font-size: 0.625rem;
  font-family: 'Ideal Sans';
  letter-spacing: 0.03em;
  text-decoration: none;
  text-transform: uppercase;
}

.text-style-dida-l-medium {
  font-size: 0.875rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
  text-transform: none;
}

.text-style-dida-m-medium {
  font-size: 0.75rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.text-style-dida-m-book {
  font-size: 0.75rem;
  font-family: 'Ideal Sans';
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}

.text-style-dida-m-light {
  font-size: 0.75rem;
  font-family: 'Ideal Sans';
  font-weight: 300;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-dida-s-medium {
  font-size: 0.625rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-decoration: none;
  text-transform: uppercase;
}

.text-style-dida-s-light {
  font-size: 0.625rem;
  font-family: 'Ideal Sans';
  font-weight: 300;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-decoration: none;
  text-transform: none;
}

.text-style-kpi-l-medium {
  font-size: 1.25rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-kpi-l-book {
  font-size: 0.875rem;
  font-family: 'Ideal Sans';
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-kpi-m-medium {
  font-size: 0.875rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-kpi-m-extralight {
  font-size: 0.875rem;
  font-family: 'Ideal Sans';
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-kpi-s-light {
  font-size: 0.75rem;
  font-family: 'Ideal Sans';
  font-weight: 300;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-kpi-s-semibold {
  font-size: 0.75rem;
  font-family: 'Ideal Sans';
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}

.text-style-title-h-3 {
  font-size: 1rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

.text-style-title-h-1 {
  font-size: 1.5rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

.text-style-title-h-2-bold {
  font-size: 1.125rem;
  font-family: 'Ideal Sans';
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

.text-style-title-h-2-light {
  font-size: 1.125rem;
  font-family: 'Ideal Sans';
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

.effect-style-tooltip {
  /* backdrop-filter: blur(4px); */
  box-shadow: 0px 0px 8px #0d8eca;
}

.effect-style-tooltip {
  /* backdrop-filter: blur(4px); */
  box-shadow: 0px 0px 8px #0d8eca;
}

.effect-style-card-cta {
  /* backdrop-filter: blur(4px); */
  box-shadow: 0px 0px 8px #0d8eca;
}

.effect-style-card-cta {
  /* backdrop-filter: blur(4px); */
  box-shadow: 0px 0px 8px #0d8eca;
}

.effect-style-card-shadow-1 {
  box-shadow: 0px 0px 4px #0d8eca;
}

.effect-style-card-shadow-2 {
  box-shadow: 0px 0px 8px #0d8eca;
}

.effect-style-card-shadow-3 {
  box-shadow: 0px 0px 64px #0d8eca;
}

.effect-style-chart-shadows-primary {
  box-shadow: inset 0px 0px 10px #00b6c9, 0px 0px 6px #00b6c9;
}

.effect-style-chart-shadows-primary {
  box-shadow: inset 0px 0px 10px #00b6c9, 0px 0px 6px #00b6c9;
}

.effect-style-chart-shadow-anr {
  box-shadow: inset 0px 0px 10px #a8bb00, 0px 0px 6px #a8bb00;
}

.effect-style-chart-shadow-anr {
  box-shadow: inset 0px 0px 10px #a8bb00, 0px 0px 6px #a8bb00;
}

.effect-style-chart-shadow-ene {
  box-shadow: inset 0px 0px 10px #68c5ea, 0px 0px 6px #68c5ea;
}

.effect-style-chart-shadow-ene {
  box-shadow: inset 0px 0px 10px #68c5ea, 0px 0px 6px #68c5ea;
}

.effect-style-chart-shadow-ict {
  box-shadow: inset 0px 0px 10px #713edc, 0px 0px 6px #713edc;
}

.effect-style-chart-shadow-ict {
  box-shadow: inset 0px 0px 10px #713edc, 0px 0px 6px #713edc;
}

.effect-style-chart-shadow-psm {
  box-shadow: inset 0px 0px 10px #055a82, 0px 0px 6px #055a82;
}

.effect-style-chart-shadow-psm {
  box-shadow: inset 0px 0px 10px #055a82, 0px 0px 6px #055a82;
}

.effect-style-chart-shadow-hlt {
  box-shadow: inset 0px 0px 10px #6ab404, 0px 0px 6px #6ab404;
}

.effect-style-chart-shadow-hlt {
  box-shadow: inset 0px 0px 10px #6ab404, 0px 0px 6px #6ab404;
}

.effect-style-chart-shadow-ind {
  box-shadow: inset 0px 0px 10px #fdb515, 0px 0px 6px #fdb515;
}

.effect-style-chart-shadow-ind {
  box-shadow: inset 0px 0px 10px #fdb515, 0px 0px 6px #fdb515;
}

.effect-style-chart-shadow-edu {
  box-shadow: inset 0px 0px 10px #e9532b, 0px 0px 6px #e9532b;
}

.effect-style-chart-shadow-edu {
  box-shadow: inset 0px 0px 10px #e9532b, 0px 0px 6px #e9532b;
}

.effect-style-chart-shadow-wus {
  box-shadow: inset 0px 0px 10px #f57f29, 0px 0px 6px #f57f29;
}

.effect-style-chart-shadow-wus {
  box-shadow: inset 0px 0px 10px #f57f29, 0px 0px 6px #f57f29;
}

.effect-style-chart-shadow-other {
  box-shadow: inset 0px 0px 10px #4e4e4e, 0px 0px 6px #4e4e4e;
}

.effect-style-chart-shadow-other {
  box-shadow: inset 0px 0px 10px #4e4e4e, 0px 0px 6px #4e4e4e;
}

.effect-style-chart-shadow-xxx {
  box-shadow: inset 0px 0px 10px #c63edc, 0px 0px 6px #c63edc;
}

.effect-style-chart-shadow-xxx {
  box-shadow: inset 0px 0px 10px #c63edc, 0px 0px 6px #c63edc;
}

.effect-style-chart-shadow-tra {
  box-shadow: inset 0px 0px 10px #00b6c9, 0px 0px 6px #00b6c9;
}

.effect-style-chart-shadow-tra {
  box-shadow: inset 0px 0px 10px #00b6c9, 0px 0px 6px #00b6c9;
}

.effect-style-chart-nonsov {
  box-shadow: inset 0px 0px 6px #afafaf, 0px 0px 3px #585858;
}

.effect-style-chart-nonsov {
  box-shadow: inset 0px 0px 6px #afafaf, 0px 0px 3px #585858;
}

html,
body {
  height: 100%;
  font-size: 14px;
  font-family: var(--ideal-sans);
  color: var(--color-basic-black);
  font-weight: 350;
  background-color: var(--color-basic-lightblue-1);
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

#root {
  height: 100%;
  font-family: var(--ideal-sans);
  font-weight: 350;
  background-color: var(--color-basic-lightblue-1);
}

.flex-1 {
  flex: 1 1;
}

.no-link,
.no-link:hover {
  color: inherit;
  text-decoration: inherit;
}

.breadcrumb-portfolio {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-basic-dark-grey);
  margin-bottom: 0px !important;
  padding-left: 45px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>' !important;
}

.previousButton {
  position: absolute;
  bottom: 20px;
  left: 40px;
  z-index: 20;
  cursor: pointer;
}

.previousButton:hover > svg {
  opacity: 0.6;
}

.nextButton:hover > svg {
  opacity: 0.6;
}

.nextButton {
  position: absolute;
  bottom: 20px;
  right: 50px;
  z-index: 20;
  cursor: pointer;
}

.previousButtonTut {
  position: absolute;
  bottom: 50%;
  left: 20px;
  z-index: 20;
  cursor: pointer;
}

.nextButtonTut {
  position: absolute;
  bottom: 50%;
  right: 50px;
  z-index: 20;
  cursor: pointer;
}

.previousButtonTut:hover > svg {
  opacity: 0.6;
}

.nextButtonTut:hover > svg {
  opacity: 0.6;
}

.breadcrumb-item {
  color: var(--color-basic-dark-grey);
}

.pointer {
  cursor: pointer;
}

.cursor-initial {
  cursor: initial;
}

.bg-primary-asian {
  background-color: var(--color-primary-solid);
}

.text-primary-asian {
  color: var(--color-primary-solid);
}

.text-color-basic-dark-grey {
  color: var(--color-basic-dark-grey);
}

.text-dark-grey-basic {
  color: var(--color-basic-dark-grey);
}

.text-white-asian {
  color: var(--color-basic-white-100);
}

.video-shadow {
  box-shadow: 0px 0px 8px rgb(13 142 202 / 22%);
}

.asian-card {
  background-color: var(--color-basic-white-100);
  box-shadow: 0px 0px 4px rgba(13, 142, 202, 0.22);
  border-radius: 10px;
  cursor: url('../public/cursor.svg'), pointer;
}

.asian-card-without-cursor {
  background-color: var(--color-basic-white-100);
  box-shadow: 0px 0px 4px rgba(13, 142, 202, 0.22);
  border-radius: 10px;
  cursor: initial;
}

.asian-card-without-cursor-detail {
  background: rgba(249, 250, 254, 0.7);
  box-shadow: 0px 0px 4px rgba(13, 142, 202, 0.22);
  border-radius: 10px;
}

.asian-card > div > div > div > svg {
  cursor: url('../public/cursor.svg'), pointer;
}

.asian-card > div > div > div > div > div > div > svg {
  cursor: url('../public/cursor.svg'), pointer;
}

.cursor-custom {
  cursor: url('../public/cursor.svg'), pointer;
}

.asian-card:hover,
.asian-card-without-cursor:hover {
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.25) !important;
  z-index: 10;
}

/* TODO: MOVE */
.top-years-lines > svg {
  vertical-align: inherit !important;
}

.top-years-lines-with-numbers > svg {
  vertical-align: inherit !important;
  background-color: var(--color-basic-lightblue-1);
  position: fixed;
  z-index: 20;
}

/* RECHARTS v2.1.12+ INJECTS AN INLINE STYLE {width: 100%, height: 100%} THAT BREAKS THE VIZs */
/* THIS OVERRIDES THAT INLINE STYLE, REVERTING TO THE PREVIOUS BEHAVIOUR */
.recharts-surface {
  width: unset !important;
  height: unset !important;
}

/* React Select Customization */

.react-select__indicator-separator,
.react-select-year__indicator-separator {
  display: none;
}

.react-select__indicator,
.react-select-year__indicator {
  color: var(--color-primary-solid) !important;
}

/* .react-select__control--is-focused > div > .react-select__indicator {
  transform: rotate(180deg);
} */

.react-select__control {
  border: 0.5px solid var(--color-primary-solid) !important;
  border-radius: 30px !important;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  background-color: transparent !important;
}

.react-select-year__control {
  border: 0.5px solid var(--color-primary-solid) !important;
  border-radius: 30px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}

.react-select__menu,
.react-select-year__menu {
  border: 1px solid var(--color-primary-solid) !important;
  border-radius: 10px !important;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  box-shadow: none !important;
  background-color: var(--color-basic-white-100) !important;
}

.react-select__placeholder,
.react-select-year__placeholder {
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  opacity: 1;
  color: var(--color-basic-black) !important;
}

.react-select__option--is-selected,
.react-select-year__option--is-selected {
  background-color: unset !important;
  font-weight: 350 !important;
  color: var(--color-primary-solid) !important;
  display: flex;
  align-items: center;
}

.react-select__option--is-selected::after,
.react-select-year__option--is-selected::after {
  content: '';
  background-image: url('../public/check.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  display: flex;
  height: 14px;
  width: 14px;
  align-items: center;
  justify-content: flex-end;
}

.react-select__option,
.react-select-year__option {
  font-weight: 350;
  min-height: 37px;
  display: flex !important;
  justify-content: space-between;
}

.react-select__option--is-focused,
.react-select-year__option--is-focused {
  background-color: #eef1fa !important;
  border-radius: 10px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px !important;
}

.min-width-card {
  min-width: 300px;
  width: 300px;
}

.min-width-card-300 {
  min-width: 300px;
}

.fw-300 {
  font-weight: 300;
}

.fw-325 {
  font-weight: 325;
}

.fw-350 {
  font-weight: 350;
}

.fw-100 {
  font-weight: 100;
}

.fs-10 {
  font-size: 12px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.rect-cluster {
  filter: drop-shadow(0px 0px 8px rgba(13, 142, 202, 0.22));
  /* filter: blur(4px); */
  border-radius: 10px;
}

.table-search,
.table-snapshot {
  font-size: 12px;
  font-weight: 325;
  border-collapse: separate;
  border-spacing: 0;
}

.unit-table-snapshot {
  color: var(--color-basic-dark-grey);
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.03em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.table-snapshot > thead > tr > th {
  font-weight: 350;
  font-size: 12px;
  position: sticky;
  position: -webkit-sticky;
  border-bottom: 2px solid;
  background-color: var(--color-basic-white-100);
  z-index: 10;
  top: 0; /* Don't forget this, required for the stickiness */
}

.table-search > thead > tr > th {
  font-weight: 400;
  position: sticky;
  position: -webkit-sticky;
  background-color: var(--color-basic-lightblue-1);
  z-index: 10;
  top: 0; /* Don't forget this, required for the stickiness */
}

.table-search > tbody > tr {
  height: 50px;
}

.table-search > tbody > tr > td {
  font-weight: 325;
  position: relative;
}

.circle-pack-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7.35px;
  gap: 3.68px;

  /* Basic/White-90 */

  background: rgba(249, 250, 254, 0.9);
  /* Basic/Lightblue-1 */

  border: 0.3675px solid #eef1fa;
  /* Tooltip */

  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 7.35px;
}

.modal-title {
  width: 100%;
}


.project-title-tooltip {
  font-size: 14px;
  max-width: 175px;
  color: var(--color-basic-black);
  font-weight: 350;
  text-transform: capitalize;
  font-family: var(--ideal-sans);
}

.title-card {
  text-align: center;
  color: var(--color-basic-dark-grey);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  padding-top: 10px;
  text-transform: uppercase;
  min-height: 28px;
}

.width-100 {
  width: 100px;
}

.bg-modal {
  background-color: #e5e5e5;
}

.CircleSector {
  height: 9px;
  width: 9px;
  border-radius: 50%;
}

.CircleSectorDisbursment {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: var(--color-sectors-solid-ene);
}

.CircleSectorAward {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: var(--color-sectors-solid-psm);
}

.bg-scroll {
  background: linear-gradient(
    0deg,
    rgba(88, 88, 88, 0.4) 0%,
    rgba(224, 224, 224, 0) 109.37%
  );
  opacity: 0.4;
  border-radius: 10px 0px 0px 10px;
  position: fixed;
  bottom: 0px;
  top: 90%;
  left: 0px;
  right: 0px;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
  overflow: -moz-scrollbars-vertical;
  overflow-x: auto;
}

.bubblesBox {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.bubblesBox::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 7px;
  height: 7px;
  opacity: 0.4;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #a4a9c1;
  opacity: 1;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.tooltip {
  height: max-content;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  font-family: var(--ideal-sans) !important;
  color: var(--color-basic-black);
}

.TooltipTotals {
  margin-top: 10px;
  bottom: 0px;
  background: rgba(249, 250, 254, 0.9);
  border: 0.5px solid #eef1fa;
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding: 14px;
  z-index: 1000;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
}

.text-ellipsis-snapshot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
}

.hover-ellipsis {
  width: 100%;
  background: #e2edf6;
  border-radius: 10px;
  padding: 5px 20px 5px 10px;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s linear;
}

.tooltip-cluster {
  background: rgba(249, 250, 254, 0.9);
  border: 0.5px solid var(--color-basic-lightblue-1);
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  font-weight: 350;
  padding: 10px;
  font-size: 12px;
  line-height: 150%;
  border-radius: 10px;
}

.search-active {
  text-underline-offset: 17px;
}

.search-disactive {
  color: rgba(13, 142, 202, 0.6);
}

/* .label-shadow {
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(4px);
} */

.bg-lightblue {
  background-color: var(--color-basic-lightblue-1);
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.spinner-import {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 0px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #0d8eca;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.opacity-05 {
  opacity: 0.5;
}

.tutorial-item {
  display: flex;
  align-items: center;
}

.tutorial-item::before {
  content: url("data:image/svg+xml,%3Csvg width='1' height='4' viewBox='0 0 1 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.5' y1='0.5' x2='0.5' y2='3.5' stroke='%23585858' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  padding-right: 10px;
  padding-bottom: 5px;
}

.tutorial-item-active {
  display: flex;
  align-items: center;
}

.tutorial-item-active::before {
  content: url("data:image/svg+xml,%3Csvg width='1' height='14' viewBox='0 0 1 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.5' y1='0.5' x2='0.500001' y2='13.5' stroke='%230D8ECA' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  padding-right: 10px;
  padding-bottom: 0px;
  padding-top: 2px;
}

.BoxText {
  font-weight: 325;
  font-size: 16px;
  line-height: 120%;
  margin-left: 60px;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
  color: #585858;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.circle-tut {
  transform: scale(0);
  transform-box: fill-box;
  transform-origin: center;
  animation: circle 0.3s linear 0.3s forwards;
}

@keyframes circle {
  to {
    transform: scale(1);
  }
}

.line-tut {
  stroke-dasharray: 320;
  stroke-dashoffset: 0;
  animation: dash 1s linear;
}

@keyframes dash {
  from {
    stroke-dashoffset: 320;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.react-select__multi-value {
  background-color: unset !important;
}

.react-select__multi-value__remove {
  display: none !important;
}

.react-select__value-container--is-multi {
  display: flex !important;
  padding-left: 0px !important;
}

.react-select__value-container--is-multi > span {
  margin-left: 8px;
}

.react-select__multi-value__label {
  font-weight: 350 !important;
  font-size: 14px !important;
  line-height: 120% !important;
}

.auto-tip-modal > .modal-content {
  background-color: unset !important;
  border: unset !important;
}

.recharts-text > tspan {
  font-family: var(--ideal-sans) !important;
}

.label-sectors {
  font-weight: 350;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */

  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Primary/Solid */

  color: var(--color-primary-solid);
}

.tooltip-sectors {
  background: rgba(249, 250, 254, 0.9);
  border: 0.5px solid #0d8eca;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */
  top: 70px;
  width: 240px;
  /* width: 25; */
  border-radius: 10px;
  position: absolute;
  z-index: 10000;
  right: 20px;
  padding: 13px;
  font-weight: 350;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  color: #585858;
}

.legend-dc {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Basic/Black */

  color: #1c1c1c;
}

.percentage-info {
  font-weight: 325;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Basic/Dark-grey */

  color: #585858;
}

.subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */
  text-align: center;
  margin-top: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Basic/Black */

  color: #1c1c1c;
}
