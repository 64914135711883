.Title {
  font-weight: 350;
  font-size: 36px;
  line-height: 43px;
  z-index: 2;
  color: var(--color-basic-white-100);
}

.ContainerTitle {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.AsianTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: var(--color-basic-white-100);
  text-transform: uppercase;
  margin-bottom: 10px;
  z-index: 2;
}

.VideoBig {
  display: none;
}

.ButtonWatch {
  background: rgba(249, 250, 254, 0.9);
  /* Tooltip */
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  width: max-content;
  padding: 10px;
  margin-top: 20px;
}

.Box {
  height: 700px;
  background-color: var(--color-basic-lightblue-1);
  padding-left: 84px;
  padding-right: 84px;
  padding-top: 58px;
  padding-bottom: 58px;
}

.BoxItem {
  height: 70px;
  color: rgba(13, 142, 202, 0.6);
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
  padding-left: 30px;
  padding-top: 12px;
  cursor: pointer;
}

.BoxItemActive {
  height: 70px;
  background-color: var(--color-basic-white-100);
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  color: var(--color-primary-solid);
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
  padding-left: 30px;
  padding-top: 12px;
  cursor: pointer;
}

.BoxAbout {
  padding-top: 33px;
  padding-bottom: 60px;
  padding-left: 187px;
  padding-right: 187px;
  display: flex;
  background-color: white;
}

.FixedMenu {
  position: fixed;
  left: 1.5rem;
  top: 1rem;
}

.BurgerCircle:hover {
  fill: #eef1fa;
  stroke: #0d8eca;
}

.ButtonTutorial {
  background: rgba(249, 250, 254, 0.9);
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  height: 37px;
  width: max-content;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
}

.ClickToExplore {
  font-weight: 325;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(13, 142, 202, 0.6);
  margin-top: 5px;
}

.Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  margin: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.BackgroundVideo {
  background: linear-gradient(
    112.13deg,
    rgba(7, 47, 156, 0.7) 19.57%,
    rgba(12, 56, 162, 0.45) 56.33%,
    rgba(17, 64, 167, 0.1) 90.85%
  );
  position: absolute;
  left: 0;
  margin: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.CloseVideo {
  position: absolute;
  cursor: pointer;
  right: 1.5rem;
  top: 1rem;
}

.CloseVideo:hover > svg > :global(.close-change) {
  fill: #eef1fa;
}

.LearnMore {
  font-weight: 350;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  color: rgba(13, 142, 202, 1);
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-decoration: none;
  margin-top: 20px;
  margin-left: 5px;
}

.LearnMore:hover {
  font-weight: 350;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  color: rgba(13, 142, 202, 1);
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-decoration: underline;
}
