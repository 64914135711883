.CircleSector {
  height: 9px;
  width: 9px;
  border-radius: 50%;
}

.Search {
  padding: 15px;
  /* box-shadow: 0px 0px 4px rgba(13, 142, 202, 0.22); */
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.25);
  border: none;
  border-radius: 100px;
  background-color: var(--color-basic-white-100);
  color: var(--color-primary-solid);
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  margin-left: 0.4em;
  background-image: url("data:image/svg+xml;utf8,<svg fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.21719 3.21738C-0.526815 6.96138 -0.526815 13.0386 3.21719 16.7826C6.9612 20.5266 13.0384 20.5266 16.7824 16.7826C20.5264 13.0386 20.5264 6.96138 16.7824 3.21738C13.0384 -0.52663 6.9612 -0.52663 3.21719 3.21738ZM14.0694 7.28695L11.3563 10L14.0694 12.713L12.7129 14.0696L9.99982 11.3565L7.28677 14.0696L5.93024 12.713L8.64329 10L5.93024 7.28695L7.28677 5.93043L9.99982 8.64348L12.7129 5.93043L14.0694 7.28695Z' fill='%230D8ECA'/></svg>");
  cursor: pointer;
}

.Search:focus {
  border: 1px solid #0d8eca;
  outline: none;
}

.Search::placeholder {
  color: rgba(13, 142, 202, 0.6);
}

.ButtonFilters {
  width: 120px;
  background: var(--color-basic-white-100);
  padding: 15px;
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--color-primary-solid);
  box-shadow: 0px 0px 4px rgba(13, 142, 202, 0.22);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary-solid);
}

.ButtonFilters:hover {
  background-color: var(--color-basic-lightblue-2) !important;
}

.FiltersContainer {
  width: 100%;
  background: var(--color-basic-white-100);
  border-radius: 30px;
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.Archive {
  font-size: 10px;
  color: var(--color-basic-dark-grey);
}

.Archive::after {
  padding-left: 8px;
  padding-right: 8px;
  content: '|';
  font-size: 14px;
}

.Filter {
  display: flex;
  align-items: center;
}

.Filter::after {
  padding-left: 8px;
  padding-right: 8px;
  content: '|';
  font-size: 14px;
}

.FilterLabel {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
}

.FilterValue {
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  margin-left: 5px;
}

.ContainerSwitch {
  display: flex;
  margin-top: 7px;
  margin-right: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  border: 0.5px solid var(--color-primary-solid);
  border-radius: 30px;
}

.ActiveItem {
  border-radius: 30px;
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  padding: 10px;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  background-color: var(--color-primary-solid);
  color: var(--color-basic-white-100);
}

.Item {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  padding: 10px;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-primary-solid);
}

.LabelClassification {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-dark-grey);
}

.ResetFilters {
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-primary-solid);
  padding-left: 35px;
  cursor: pointer;
}

.HeaderModal {
  padding-left: 35px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  padding-top: 55px;
  padding-bottom: 18px;
}

.Results {
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  margin-right: 33px;
  color: var(--color-basic-black);
}

.ButtonResults {
  background: var(--color-primary-solid);
  box-shadow: 0px 0px 4px rgba(13, 142, 202, 0.22);
  border-radius: 100px;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  border: none;
  height: 44px;
}

.ButtonResults:hover {
  opacity: 0.8;
  background: var(--color-primary-solid);
}

.CloseModal {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.BurgerCircle:hover {
  fill: #eef1fa;
  stroke: #0d8eca;
}

.ModalSearch {
  font-family: var(--ideal-sans);
}

.ModalSearch > :global.modal-content {
  background: var(--color-basic-white-100);
  border: none !important;
  border-radius: 23px;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.25);
}

.ModalSearchBody {
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  border: none;
}

.FilterNumber {
  background: #e2edf6;
  border-radius: 10px;
  padding: 5px;
}

.FilterNumberSelect {
  width: 18px;
  background: #e2edf6;
  border-radius: 10px;
  padding: 5px;
  margin-left: 5px;
}

.ThUnit {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  /* identical to box height, or 12px */

  letter-spacing: 0.03em;

  /* Basic/Dark-grey */

  color: #585858;
}

.DownloadButton {
  display: flex;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  width: 145px;
  color: var(--color-primary-solid);
  position: relative;
}

.ArrowDownload {
  position: absolute;
  top: -5px;
}

.DownloadButton:hover .ArrowDownload {
  top: 0px;
  transition: all linear 0.3s;
}

.LineDownload {
  margin-top: 10px;
}
