.Title {
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 29px;
}

.About{
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
}

.Link {
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-basic-dark-grey);
}

.Link:hover {
  color: var(--color-basic-dark-grey);
}

.LinkActive {
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-primary-solid);
}

.LinkActive:hover {
  color: var(--color-primary-solid);
}

.ContainerLink {
  padding-bottom: 40px;
}



.Subtitle {
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  color: var(--color-basic-dark-grey);
  padding-top: 18px;
}



.MiniTitle {
  font-weight: 350;
  font-size: 10px;
  line-height: 120%;
  opacity: 0.9;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding-top: 36px;
}

.Paragraph {
  padding-top: 20px;
  font-style: normal;
  font-weight: 275;
  font-size: 14px;
  line-height: 120%;
}

.ParagraphLink {
  padding-top: 30px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 275;
  font-size: 14px;
  line-height: 120%;
  border-bottom: 1px solid rgba(13, 142, 202, 0.6);
}

.ExternalLink {
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-basic-black);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

.ExternalLink:hover {
  color: var(--color-basic-black);
}

.Rating {
  display: flex;
}

.BlockRating {
  padding-left: 32px;
}

.OnTrack {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Sectors/Solid/ANR */

  color: #a8bb00;

  opacity: 0.9;
  padding-top: 10px;
}

.ForAttention {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Sectors/Solid/ANR */

  color: #fdb515;

  opacity: 0.9;
  padding-top: 10px;
}

.AtRisk {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Sectors/Solid/ANR */

  color: #e9532b;

  opacity: 0.9;
  padding-top: 10px;
}
