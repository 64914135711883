.tetris-bar-chart-pill {
  transition: opacity 0.25s ease-in;
  opacity: 0;
}
.tetris-bar-chart-container .recharts-label-list {
  transition: opacity 0.25s ease-in;
  opacity: 0;
}

.tetris-bar-chart-container .project-rated {
  transition: opacity 0.25s ease-in;
  opacity: 0;
}

.tetris-bar-chart-container.test-hover .project-rated,
.tetris-bar-chart-container:hover .project-rated {
  opacity: 1;
}

.tetris-bar-chart-container.test-hover .recharts-label-list,
.tetris-bar-chart-container:hover .recharts-label-list {
  opacity: 1;
}
.tetris-bar-chart-container.test-hover .tetris-bar-chart-pill,
.tetris-bar-chart-container:hover .tetris-bar-chart-pill {
  opacity: 1;
}

.tetris-bar-chart-middle-line {
  transition: all 0.25s ease-in;
}

.tetris-bar-chart-container.test-hover .tetris-bar-chart-middle-line,
.tetris-bar-chart-container:hover .tetris-bar-chart-middle-line {
  stroke: var(--color-basic-white-100);
}

.tetris-bar-chart-card {
  transition: transform 0.25s ease-in;
  z-index: 1;
}
.tetris-bar-chart-year {
  transition: all 0.25s ease-in;
  color: var(--color-light-blue-grey);
  font-size: 12px;
}

.tetris-bar-chart-container.test-hover .tetris-bar-chart-card,
.tetris-bar-chart-container:hover .tetris-bar-chart-card {
  z-index: 2;
  /* transform: translateX(-50px); */
  /* BECOME ASIAN CARD */
  background-color: var(--color-basic-white-100);
  box-shadow: 0px 0px 4px rgba(13, 142, 202, 0.22);
  border-radius: 10px;
}

.tetris-bar-chart-container.test-hover .tetris-bar-chart-tick,
.tetris-bar-chart-container:hover .tetris-bar-chart-tick {
  display: none;
}

.tetris-bar-chart-year {
  font-weight: 300;
}

.tetris-bar-chart-container.test-hover .tetris-bar-chart-year,
.tetris-bar-chart-container:hover .tetris-bar-chart-year {
  font-weight: 350;
  z-index: 2;
  color: var(--color-basic-dark-grey);
  /* transform: translateX(-50px) translateY(-2px); */
}

.label-vertical{
  position: absolute;
  left: 0px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}