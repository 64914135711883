.Title {
  text-align: center;
  color: var(--color-basic-dark-grey);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  margin-top: 5px;
}

.Subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */
  text-align: center;
  margin-top: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Basic/Black */

  color: #1c1c1c;
}

.ContainerAmount {
  margin-top: 16px;
}

.LabelAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.ValueAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.Unit {
  font-weight: 275;
  font-size: 14px;
  margin-right: 4px;
  line-height: 120%;
  color: var(--color-basic-black);
}
