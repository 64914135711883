.Tutorial {
  max-width: 100% !important;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  background: var(--color-basic-lightblue-1);
  margin-top: 12px;
  margin-top: 12px;
  margin-left: 20px;
  height: calc(100% - 24px);
  margin-right: 20px;
  border-radius: 23px;
  font-family: var(--ideal-sans);
}

.ActiveItem {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  color: var(--color-primary-solid);
}

.ModalHeader {
  border: none;
  border-radius: 23px 23px 0px 0px;
  background: var(--color-basic-lightblue-1);
  display: flex !important;
  height: 64px;

  padding-left: 24px;
}

.ModalHeader > :global.modal-title {
  font-weight: 325;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.03em;
  align-items: center;
  display: flex !important;
  justify-content: space-between;
  text-transform: uppercase;
}

.Tutorial > :global.modal-content {
  background: var(--color-basic-lightblue-1);
  border: none !important;
  border-radius: 23px;
}

.Close {
  margin-right: 10px;
  cursor: pointer;
}

.Close:hover > svg {
  opacity: 0.6;
}

.AccordionHeader > :global.accordion-button {
  background-color: unset;
  padding-top: 11px;
  border-bottom: 1px solid #A4A9C1;
  border-left: none;
  font-weight: 375;
  font-size: 12px;
  line-height: 120%;
  padding-left: 0px;
  padding-bottom: 0px;
}

.AccordionHeader > :global.accordion-button:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.AccordionHeader > :global.accordion-button:not(.collapsed){
  color: var(--color-primary-solid);
}

.AccordionItem {
  border: none;
}

.AccordionBody {
  background: #eef1fa;
 
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
}

.ContainerAccordion {
  height: calc(100vh - 105px);
  border-radius: 0px 10px 10px 0px;
}

.TutPage {
  padding-left: 64px;
  padding-right: 64px;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-basic-dark-grey);
  min-height: 110px;
  height: 110px;
}

.TitTutPage {
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-primary-solid);
  margin-bottom: 8px;
}
