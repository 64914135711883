.SignIn {
  padding-top: 30px;
  text-align: center;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 120%;
}

.ButtonLogin {
  margin-top: 80px;
  width: 100%;
  margin-bottom: 20px;
  background-color: rgba(249, 250, 254, 0.9);
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}

.CardLogin {
  /* box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22); */
  border-radius: 20px;
  background-color: var(--color-basic-lightblue-1);
  width: 350px;
}

.LoginInput {
  width: 100%;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #8F9091;
  background: transparent;
  font-size: 12px;
}

.LoginInput:focus {
    outline: none;
  }

.LabelLogin{
    color: var(--color-light-blue-grey);
    font-size: 12px;
}
