.ContainerSwitch {
  display: flex;
  margin-top: 16px;
  margin-right: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  border: 0.5px solid var(--color-primary-solid);
  border-radius: 30px;
  background-color: var(--color-basic-white-100);
}

.ActiveItem {
  border-radius: 30px;
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  padding: 10px;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  background-color: var(--color-primary-solid);
  color: var(--color-basic-white-100);
}

.Item {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  padding: 10px;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-primary-solid);
}

.ContainerSwitch:hover {
  background-color: var(--color-basic-lightblue-2) !important;
}
