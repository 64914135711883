.Import {
  background-color: var(--color-primary-solid);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.ImportBlock {
  width: 70%;
  border-radius: 0px 10px 10px 0px;
  background: #f9fafe;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;
  padding-top: 64px;
}

.Subtitle {
  font-weight: 350;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: var(--color-primary-solid);
  padding-top: 10px;
}

.ImportBox {
  margin-top: 64px;
  background: rgba(164, 169, 193, 0.1);
  border-radius: 10px;
  width: 70%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImportBoxError {
  background: #a4a9c1;
  text-transform: uppercase;
  margin-top: 64px;
  border-radius: 10px;
  width: 70%;
  height: 200px;
  color: #f9fafe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ImportBoxSuccess {
  margin-top: 64px;
  background: #0d8eca;
  border-radius: 10px;
  width: 70%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f9fafe;
  font-weight: 350;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}

.ImportButton {
  background: #0d8eca;
  /* Card/Shadow-3 */
  cursor: pointer;
  box-shadow: 0px 0px 64px rgba(13, 142, 202, 0.22);
  border-radius: 10px;
  width: max-content;
  margin-left: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
  color: #f9fafe;
}

.GoToWebSite {
  margin-top: 12px;
  background: rgba(249, 250, 254, 0.9);
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.LastUpdate {
  font-weight: 350;
  font-size: 12px;
  line-height: 150%;
  color: #a4a9c1;
}

.Date {
  font-weight: 350;
  font-size: 12px;
  line-height: 150%;
  margin-left: 6px;
}

.ImportInput {
  width: 280px;
  border: 1px solid #0d8eca;
  /* Card/Shadow-2 */

  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.25);
  border-radius: 100px;
  padding: 15px;
  color: var(--color-primary-solid);
}

.ImportInput::placeholder {
  color: rgba(13, 142, 202, 0.6);
}
