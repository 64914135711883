@font-face {
  font-family: Ideal Sans;
  src: url('fonts/IdealSans-Semibold-Pro.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Ideal Sans;
  src: url('fonts/IdealSans-Medium-Pro.otf') format('opentype');
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: Ideal Sans;
  src: url('fonts/IdealSans-Book-Pro.otf') format('opentype');
  font-weight: 325;
  font-style: normal;
}

@font-face {
  font-family: Ideal Sans;
  src: url('fonts/IdealSans-Light-Pro.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Ideal Sans;
  src: url('fonts/IdealSans-XLight-Pro.otf') format('opentype');
  font-weight: 275;
  font-style: normal;
}

