.Title {
  text-align: center;
  color: var(--color-basic-dark-grey);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  padding-top: 10px;
  text-transform: capitalize;
  min-height: 28px;
}

.TitleUppercase {
  text-align: center;
  color: var(--color-basic-dark-grey);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  padding-top: 10px;
  text-transform: uppercase;
  min-height: 28px;
}

.Subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */
  text-align: center;
  margin-top: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Basic/Black */

  color: #1c1c1c;
}

.ContainerAmount {
  margin-top: 0px;
}

.LabelAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  margin-top: 20px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.ValueAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Circle {
  box-shadow: 0px 0px 6px rgba(0, 182, 201, 0.3),
    inset 0px 0px 10px rgba(0, 182, 201, 0.25);
}

.Unit {
  font-weight: 275;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-basic-black);
  margin-right: 4px;
}
