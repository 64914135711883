.ChartPercent {
  display: flex;
  align-items: center;
}

.BgChartPercent {
  height: 10px;
  width: calc(100% - 48px);
  background: rgba(104, 197, 234, 0.2);
  z-index: 1;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 16px;
}

.BgPercentActive {
  height: 10px;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  opacity: 1;
  box-shadow: 0px 0px 6px rgba(5, 90, 130, 0.3),
    inset 0px 0px 10px rgba(5, 90, 130, 0.25);
}
