.ContainerSwitch {
  display: flex;
  margin-top: 7px;
  justify-content: flex-end;
  margin-bottom: 16px;
  cursor: pointer;
  border: 0.5px solid var(--color-primary-solid);
  border-radius: 30px;
  width: max-content;
  background-color: white;
}

.ActiveItem {
  border-radius: 30px;
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  padding: 10px;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  background-color: var(--color-primary-solid);
  color: var(--color-basic-white-100);
}

.Item {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  padding: 10px;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-primary-solid);
}

.Item:hover {
  background-color: var(--color-basic-lightblue-2) !important;
  border-radius: 30px;
}

.LegendCompleted {
  position: fixed;
  bottom: 0px;
  height: 37px;
  background-color: #eef1fa;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  padding-left: 20px;
  color: #1C1C1C;
}
