.LeftSidebar {
  width: 25%;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 54px;
  border-radius: 0px 10px 10px 0px;
  background-color: white;
}

.BurgerMenu {
  margin-bottom: 20px;
  cursor: pointer;
}

.BurgerCircle:hover {
  fill: #eef1fa;
  stroke: #0d8eca;
}

.Title {
  opacity: 0.9;
  color: var(--color-basic-dark-grey);
  font-weight: 350;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.Subtitle {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-basic-black);
}

.Date {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-blue-grey);
  margin-top: 8px;
}

.Filters {
  margin-top: 30px;
}

.Page {
  width: 75%;
  background-color: var(--color-basic-lightblue-1);
}

.Menu {
  max-width: 100% !important;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  /* border: 1px solid var(--color-basic-lightblue-1); */
  background: var(--color-basic-white-100);
  height: 347px;
  margin-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 23px;
  font-family: var(--ideal-sans);
}

.Menu > :global.modal-content {
  background: var(--color-basic-white-100);
  border: none !important;
  border-radius: 23px;
}

.MenuContent {
  background: var(--color-basic-white-100);
  border: none;
  border-radius: 23px;
}

.CloseMenu {
  position: absolute;
  margin-top: -14px;
  margin-left: -15px;
  cursor: pointer;
  z-index: 10;
  /* background: var(--color-basic-white-100); */
  /* Basic/Lightblue-1 */
  /* border: 1px solid var(--color-basic-lightblue-1); */
  /* Tooltip */
  /* position: absolute;
  left: 30px;
  top: 10px;
  width: calc(100% - 40px);
  right: 20px;
  height: 277px;
  z-index: 10;
  padding-left: 0px;
  padding-top: 0px;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  border-radius: 23px;
  display: flex; */
}

.BlockHomePage {
  padding-top: 78px;
  color: var(--color-basic-black);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  padding-left: 46px;
}

.BorderImport {
  padding-left: 46px;
  padding-top: 38px;
}

.BlockImportData {
  /* padding-top: 38px; */
  color: var(--color-basic-black);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  padding-left: 46px;
}

.BlockBorder {
  border-bottom: 1px solid #e2edf6;
  padding-top: 38px;
}

.BlockLogout {
  /* padding-top: 38px; */
  color: var(--color-basic-black);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
}

.BlockLogout:hover > div > a > span > svg > .ArrowLogout {
  d: path(
    'M17.1667 12.9998L29.6667 12.9998M29.6667 12.9998L23.8334 7.1665M29.6667 12.9998L23.8334 18.8332'
  );
  transition: 0.3s linear all;
}

.BlockPortfolioInformation {
  /* padding-left: 100px; */
  padding-top: 56px;
}

.CompletedProductsAndPipelines {
  /* padding-left: 100px; */
  padding-top: 56px;
}

.BlockSearch {
  padding-top: 78px;
  /* padding-left: 100px; */
}

.BlockAbout {
  padding-top: 78px;
  /* padding-left: 100px; */
}

.LabelBlock {
  font-style: normal;
  font-weight: 350;
  font-size: 10px;
  line-height: 120%;
  color: var(--color-basic-dark-grey);
  text-transform: uppercase;
}

.LinkMenu {
  color: var(--color-primary-solid);
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  text-decoration: none;
  line-height: 19px;
}

.LinkBlack {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-basic-black);
  text-decoration: none;
}

.LinkBlack:hover {
  color: var(--color-basic-black);
}

.LinkBlack:hover > span > img {
  color: var(--color-primary-solid);
  margin-left: 10px;
  transition: 0.3s linear all;
  /* text-decoration: underline; */
}

.LinkMenu:hover {
  color: var(--color-primary-solid);
}

.LinkMenu:hover > span > img {
  color: var(--color-primary-solid);
  margin-left: 10px;
  transition: 0.3s linear all;
  /* text-decoration: underline; */
}

.LearnMore {
  font-weight: 350;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  color: rgba(13, 142, 202, 1);
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-decoration: none;
}

.LearnMore:hover {
  font-weight: 350;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  color: rgba(13, 142, 202, 1);
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-decoration: underline;
}
