.Title {
  text-align: center;
  color: var(--color-basic-dark-grey);
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 100%;
  margin-top: 5px;
}

.Subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */
  text-align: center;
  margin-top: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Basic/Black */

  color: #1c1c1c;
}

.ContainerAmount {
  margin-top: 16px;
}

.LabelAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.ValueAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.ContainerChart {
  min-height: 200px;
  height: 200px;
  margin-top: 10px;
  position: relative;
}

.TooltipChart {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  overflow: auto;
  bottom: 0px;
  background: rgba(249, 250, 254, 0.9);
  border: 0.5px solid #eef1fa;
  /* Tooltip */
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgba(13, 142, 202, 0.22);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 5px;
}

.ValueAmount {
  font-family: var(--ideal-sans);
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 120%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-basic-black);
}

.Unit {
  font-weight: 275;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-basic-black);
  margin-right: 4px;
}
